import React from "react";
import "./Confirmation.scss";

import classnames from "classnames";

import Button from "../Button/Button";

function Confirmation(props) {
  return (
    <div
      className={classnames(
        "confirmation",
        props.classes,
        props.active ? "active" : "",
        props.withBg ? "withBg" : ""
      )}
      style={props.style}
    >
      <div>Are you sure?</div>
      <div>
        <Button
          style={{ fontSize: ".85em", padding: "5px 10px" }}
          onClick={props.yesHandler}
        >
          Yes
        </Button>
      </div>
      <div>
        <Button
          style={{ fontSize: ".85em", padding: "5px 10px" }}
          onClick={props.noHandler}
        >
          No
        </Button>
      </div>
    </div>
  );
}

export default Confirmation;
