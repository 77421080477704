import openSocket from "socket.io-client";
import Peer from "peerjs";
import { env_config } from "./config";

const { websocket, peerjsEndpoint } = env_config;

let socketInstance = null;
let peers = {};

const initializePeerConnection = () => {
  return new Peer("", {
    host: peerjsEndpoint,
    path: "/peerjs",
    secure: true,
    port: 443,
    config: {
      iceServers: [
        { url: "stun:stun.gmx.net:3478" },
        { url: "stun:stun.l.google.com:19302" },
        { url: "stun:stun1.l.google.com:19302" },
        { url: "stun:stun2.l.google.com:19302" },
        { url: "stun:stun3.l.google.com:19302" },
        {
          url: "turn:relay.backups.cz",
          credential: "webrtc",
          username: "webrtc",
        },
        {
          url: "turn:relay.backups.cz?transport=tcp",
          credential: "webrtc",
          username: "webrtc",
        },
        {
          url: "turn:numb.viagenie.ca",
          credential: "muazkh",
          username: "webrtc@live.com",
        },
        {
          url: "turn:192.158.29.39:3478?transport=udp",
          credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
          username: "28224511:1379330808",
        },
        {
          url: "turn:192.158.29.39:3478?transport=tcp",
          credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
          username: "28224511:1379330808",
        },
        {
          url: "turn:turn.bistri.com:80",
          credential: "homeo",
          username: "homeo",
        },
        {
          url: "turn:turn.anyfirewall.com:443?transport=tcp",
          credential: "webrtc",
          username: "webrtc",
        },
      ],
    },
    // port: 8001,
  });
};

const initializeSocketConnection = () => {
  return openSocket.connect(websocket, {
    secure: true,
    reconnection: true,
    rejectUnauthorized: false,
    reconnectionAttempts: 10,
  });
};

class Connection {
  videoContainer = {};
  message = [];
  settings;
  streaming = false;
  myPeer;
  socket;
  myID = "";
  user = {};
  isAdmin = false;
  pollID = "";
  startOrigin = false;
  handlePermission = function () {};
  waitForAnswer = function () {};
  pollEnded = function () {};
  showVotesEnded = function () {};
  pollStarted = function () {};

  constructor(settings) {
    this.settings = settings;
    this.pollID = settings.params.pollID;
    this.user = settings.params.user;
    this.startOrigin = settings.params.startOrigin;
    this.isAdmin = settings.params.isAdmin === true ? true : false;
    this.pollEnded = settings.params.pollEnded;
    this.showVotesEnded = settings.params.showVotesEnded;
    this.myPeer = initializePeerConnection();
    this.socket = initializeSocketConnection();
    this.initializeSocketEvents();
    this.initializePeersEvents();
  }

  initializeSocketEvents = () => {
    this.socket.on("connect", () => {
      console.log("socket connected");
    });

    this.socket.on("user-disconnected", ({ user, userID }) => {
      console.log("user disconnected-- closing peers", user);
      peers[userID] && peers[userID].close();
      this.removeVideo(userID);
    });

    this.socket.on("get-permission", (data) => {
      if (this.waitForAnswer) {
        this.waitForAnswer(true);
        this.getVideoAudioStream().then((stream) => {
          if (stream) {
            this.streaming = true;
            this.createAltVideo({ id: this.myID, stream });
            this.socket.emit("get-poll-listeners", {
              id: this.myID,
            });
            this.socket.on("send-poll-listeners", (data) => {
              data.forEach((user) => {
                if (user.userID !== this.myID) {
                  this.myPeer.call(user.userID, stream, {
                    metadata: { id: this.myID, isGuest: true },
                  });
                }
              });
            });
            this.setPeersListeners(stream);
            this.newUserConnection(stream, true);
          }
        });
      }
    });

    this.socket.on("permission-ask-declined", () => {
     this.waitForAnswer?.(false);
    });

    this.socket.on("poll-ended", (results) => {
      this.pollEnded?.(results);
    })

    this.socket.on("show-votes-ended", () => {
      this.showVotesEnded?.();
    })

    if (this.isAdmin === true) {
      if(this.startOrigin === true)
        this.socket.emit("poll-start", (this.user))

      this.socket.on("ask-admin-for-permission", (user) => {
        this.handlePermission?.(user);
      });
    }

    this.socket.on("disconnect", () => {
      console.log("socket disconnected --");
    });
    this.socket.on("error", (err) => {
      console.log("socket error --", err);
    });
  };

  initializePeersEvents = () => {
    this.myPeer.on("open", (id) => {
      this.myID = id;
      const userData = {
        userID: id,
        user: this.user,
        pollID: this.pollID,
        isAdmin: this.isAdmin,
      };
      console.log("peers established and joined room", userData);
      this.socket.emit("join-poll", userData);
      if (this.isAdmin === true) this.setNavigatorToStream();
      else this.setUserToListen();
    });
    this.myPeer.on("error", (err) => {
      console.log("peer connection error", err);
      // this.myPeer.reconnect();
    });
  };

  askForPermission = () => {
    this.socket.emit("ask-permission", { id: this.myID, user: this.user });
  };

  setUserToListen = () => {
    this.getVideoAudioStream();
    this.setPeersListeners(null);
  };

  setNavigatorToStream = () => {
    this.getVideoAudioStream().then((stream) => {
      if (stream) {
        this.setPeersListeners(stream);
        this.streaming = true;
        this.createVideo({ id: this.myID, stream });
        this.socket.emit("get-poll-listeners", {
          id: this.myID,
        });
        this.socket.on("send-poll-listeners", (data) => {
          data.forEach((user) => {
            if (user.userID !== this.myID) {
              this.myPeer.call(user.userID, stream, {
                metadata: { id: this.myID, isGuest: false },
              });
            }
          });
        });
        this.newUserConnection(stream, false);
      }
    });
  };

  getVideoAudioStream = (video = true, audio = true) => {
    let quality = this.settings.params?.quality;
    if (quality) quality = parseInt(quality);
    const myNavigator =
      navigator.mediaDevices.getUserMedia ||
      navigator.mediaDevices.webkitGetUserMedia ||
      navigator.mediaDevices.mozGetUserMedia ||
      navigator.mediaDevices.msGetUserMedia;

    return myNavigator({
      video: video
        ? {
            frameRate: quality ? quality : 12,
            noiseSuppression: true,
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 720, max: 1080 },
          }
        : false,
      audio: audio,
    });
  };

  createAltVideo = (createObj) => {
    if (!document.getElementById(createObj.id)) {
      const videoWrapper = document.getElementsByClassName("videoWrapper")[0];
      const video = document.createElement("video");
      video.srcObject = createObj.stream;
      video.id = createObj.id;
      video.autoplay = true;
      video.playsInline = true;
      if (this.myID === createObj.id) video.muted = true;
      videoWrapper.appendChild(video);
    }
  };

  createVideo = (createObj) => {
    const video = document.getElementsByClassName("video-presenter")[0];
    if (video && video.id !== createObj.id) {
      video.srcObject = createObj.stream;
      video.id = createObj.id;
      video.autoplay = true;
      video.playsInline = true;
      if (this.myID === createObj.id) video.muted = true;
    }
  };

  setPeersListeners = (stream) => {
    this.myPeer.off("call");
    this.myPeer.on("call", (call) => {
      if (stream !== null) call.answer(stream);
      else call.answer();
 
      call.on("stream", (userVideoStream) => {
        console.log("user stream data", userVideoStream);
        if (call.metadata.isGuest === true)
          this.createAltVideo({
            id: call.metadata.id,
            stream: userVideoStream,
          });
        else {
          this.createVideo({ id: call.metadata.id, stream: userVideoStream });
        }
      });
      call.on("close", () => {
        console.log("closing peers listeners", call.metadata.id);
        this.removeVideo(call.metadata.id);
      });
      call.on("error", () => {
        console.log("peer error ------");
        this.removeVideo(call.metadata.id);
      });
      peers[call.metadata.id] = call;
    });
  };
  newUserConnection = (stream, isGuest) => {
    this.socket.on("new-user-connected", (userData) => {
      console.log("New User Connected", userData);
      setTimeout(() => {
        this.connectToNewUser(userData, stream, isGuest);
      }, 1250);
    });
  };

  connectToNewUser(userData, stream, isGuest) {
    const { userID } = userData;
    console.log('calling !')
    console.log(userData);
    const call = this.myPeer.call(userID, stream, {
      metadata: { id: this.myID, isGuest },
    });

    call.on("stream", (userVideoStream) => {
      console.log("new video stream");
      this.createVideo({ id: userID, stream: userVideoStream, userData });
    });
    call.on("close", () => {
      console.log("closing new user", userID);
      this.removeVideo(userID);
    });
    call.on("error", () => {
      console.log("peer error ------");
      this.removeVideo(userID);
    });

    peers[userID] = call;
  }

  getMyVideo = (id = this.myID) => {
    return document.getElementById(id);
  };

  reInitializeStream = (video, audio, type = "userMedia") => {
    const media =
      type === "userMedia"
        ? this.getVideoAudioStream(video, audio)
        : navigator.mediaDevices.getDisplayMedia();
    return new Promise((resolve) => {
      media.then((stream) => {
        if (type === "displayMedia") {
          this.toggleVideoTrack({ audio, video });
          this.socket.emit("display-media", true);
        }
        this.createVideo({ id: this.myID, stream });
        replaceStream(stream);
        resolve(true);
      });
    });
  };

  toggleVideoTrack = (status) => {
    const myVideo = this.getMyVideo();
    if (myVideo)
      myVideo.srcObject?.getVideoTracks().forEach((track) => {
        if (track.kind === "video") {
          track.enabled = status.video;
        }
      });
  };

  toggleAudioTrack = (status) => {
    const myVideo = this.getMyVideo();
    if (myVideo)
      myVideo.srcObject?.getAudioTracks().forEach((track) => {
        if (track.kind === "audio") track.enabled = status.audio;
      });
  };

  removeVideo = (id) => {
    const video = document.getElementById(id);
    if (video) {
      if (!video.classList.contains("video-presenter")) video.remove();
      else if (video.classList.contains("video-presenter")) {
        video.srcObject = null;
      }
    }
  };

  destroyConnection = () => {
    const myVideo = this.getMyVideo();
    if (myVideo)
      myVideo.srcObject?.getTracks().forEach((track) => {
        track.stop();
      });
    socketInstance?.socket.disconnect();
    this.myPeer.destroy();
  };
}

const replaceStream = (mediaStream) => {
  Object.values(peers).map((peer) => {
    peer.peerConnection?.getSenders().map((sender) => {
      if (sender.track.kind === "audio") {
        if (mediaStream.getAudioTracks().length > 0) {
          sender.replaceTrack(mediaStream.getAudioTracks()[0]);
        }
      }
      if (sender.track.kind === "video") {
        if (mediaStream.getVideoTracks().length > 0) {
          sender.replaceTrack(mediaStream.getVideoTracks()[0]);
        }
      }
      return sender;
    });
    return peer;
  });
};

export function createSocketConnectionInstance(settings = {}) {
  return (socketInstance = new Connection(settings));
}
