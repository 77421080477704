import React, { useState, useEffect } from "react";

import classnames from "classnames";

import Confirmation from "../../Confirmation/Confirmation";

import {
  deletePoll,
  activatePoll,
} from "../../../functions/actions/pollActions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit, faGlobe } from "@fortawesome/free-solid-svg-icons";

function Item(props) {
  const [isActive, setIsActive] = useState(false);
  const [singleActive, setSingleActive] = useState(false);

  const editPoll = (pollId) => {
    props.history.push(`/admin/poll/${pollId}`);
  };

  const deletePoll = (pollId) => {
    props
      .deletePoll({
        _id: props.auth.user.id,
        pollId,
      })
      .then(() => props.getPolls());
  };

  const activatePoll = (pollId) => {
    props
      .activatePoll({
        _id: props.auth.user.id,
        pollId,
      })
      .then(() => props.getActive(pollId));
  };

  useEffect(() => {
    if (props.isActive === true && (props.poll.active === true || props.poll.showVotes === true)) {
      setIsActive(true);
      setSingleActive(true);
    } else if (props.isActive === true && props.poll.active === false)
      setIsActive(true);
    else if(props.isActive === false && props.poll.active === false) {
      setIsActive(false);
      setSingleActive(false);
    }
  }, [props.poll.active, props.isActive]);

  return (
    <div
      className={classnames(
        "singlePoll",
        isActive && !singleActive
          ? "faded"
          : isActive && singleActive
          ? "active"
          : ""
      )}
    >
      <div className="title">{props.poll.title}</div>
      {isActive && singleActive ? (
        <div className="onlineDotWrapper">
          <div className="onlineDot"></div>
          <div>Active</div>
        </div>
      ) : (
        <div className="actionBarPoll">
          <div className="activatePoll">
            <Confirmation
              withBg={true}
              noHandler={() => props.setConfirmationActivate(null)}
              yesHandler={() => activatePoll(props.poll._id)}
              active={props.confirmationActivate}
              classes="right"
              style={{ marginRight: "2em", fontSize: ".85em" }}
            />
            <FontAwesomeIcon
              title={"Activate poll"}
              icon={faGlobe}
              onClick={
                !isActive
                  ? () => {
                      if (props.confirmationActive) props.setConfirmation(null);
                      props.confirmationActivate
                        ? props.setConfirmationActivate(null)
                        : props.setConfirmationActivate(props.i);
                    }
                  : null
              }
            />
          </div>
          <div className="deletePoll">
            <Confirmation
              withBg={true}
              noHandler={() => props.setConfirmation(null)}
              yesHandler={() => deletePoll(props.poll._id)}
              active={props.confirmationActive}
              classes="right"
              style={{ marginRight: "2em", fontSize: ".85em" }}
            />
            <FontAwesomeIcon
              title={"Delete poll"}
              icon={faTrashAlt}
              onClick={
                !isActive
                  ? () => {
                      if (props.confirmationActivate)
                        props.setConfirmationActivate(null);
                      props.confirmationActive
                        ? props.setConfirmation(null)
                        : props.setConfirmation(props.i);
                    }
                  : null
              }
            />
          </div>
          <div className="editPoll">
            <FontAwesomeIcon
              title={"Edit poll"}
              icon={faEdit}
              onClick={!isActive ? () => editPoll(props.poll._id) : null}
            />
          </div>
        </div>
      )}
    </div>
  );
}

Item.propTypes = {
  activatePoll: PropTypes.func.isRequired,
  deletePoll: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deletePoll, activatePoll })(
  withRouter(Item)
);
