import axios from "../../axios";

import { GET_ERRORS } from "./types";

export const activatePoll = (pollData) => (dispatch) => {
  return new Promise((res, rej) => {
    axios
      .post("/covid-poll-meeting/polls/activate", pollData)
      .then((data) => res(data))
      .catch((err) =>
        rej(
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          })
        )
      );
  });
};

export const createPoll = (history, pollData) => (dispatch) => {
  axios
    .post("/covid-poll-meeting/polls/create", pollData)
    .then(() => {
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
      history.push("/admin");
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const deletePoll = (pollData) => (dispatch) => {
  return new Promise((res, rej) => {
    axios
      .post("/covid-poll-meeting/polls/delete", pollData)
      .then((data) => res(data))
      .catch((err) =>
        rej(
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          })
        )
      );
  });
};

export const editPoll = (history, pollData) => (dispatch) => {
  axios
    .post("/covid-poll-meeting/polls/edit", pollData)
    .then(() => {
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
      history.push("/admin");
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
