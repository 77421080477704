import React from 'react'
import './Error.scss'

function Error(props) {
    return (
        <div className="errorText">
            {props.text}
        </div>
    )
}

export default Error
