import React, { useState, useEffect, useCallback } from "react";
import "./PollList.scss";

import Button from "../../Button/Button";
import Item from "./Item";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import axios from "../../../axios";

function PollList(props) {
  const [polls, setPolls] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [confirmationActive, setConfirmationActive] = useState(null);
  const [confirmationActivate, setConfirmationActivate] = useState(null);

  const getPolls = useCallback(async () => {
    const req = await axios.get("/covid-poll-meeting/polls/get", {
      params: {
        _id: props.auth.user.id,
      },
    });

    setPolls(req.data);
  }, [props.auth.user.id]);

  useEffect(() => {
    getPolls();
  }, [props.auth.user.id, props.isActive, getPolls]);

  useEffect(() => {
    setIsActive(props.isActive);
    setConfirmationActive(false);
    setConfirmationActivate(false);
  }, [props.isActive])

  return (
    <div className="pollList">
      <div className="polls">
        {polls.map((poll, i) => (
          <Item
            key={"poll" + i}
            getActive={props.getActive}
            getPolls={getPolls}
            i={i}
            poll={poll}
            isActive={isActive}
            confirmationActive={confirmationActive === i ? true : false}
            confirmationActivate={confirmationActivate === i ? true : false}
            setConfirmation={(index) => setConfirmationActive(index)}
            setConfirmationActivate={(index) => setConfirmationActivate(index)}
          />
        ))}
      </div>
      <div className="addNewPoll">
        <Button
          style={{ width: "100%" }}
          onClick={() => props.history.push("/admin/poll")}
          disabled={isActive ? true : false}
        >
          Add new poll
        </Button>
      </div>
    </div>
  );
}

PollList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(PollList));
