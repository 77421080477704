import React, { useState, useEffect } from "react";
import "./Question.scss";

import Answer from "./Answer";

function Question(props) {
  const [answered, setAnswered] = useState(null);

  const addVote = (data) => {
    props.socket.emit("vote", { ...data, userId: props.user });
  };

  const submitAnswer = (i) => {
    const upsert = (array, item) => {
      const i = array.findIndex((item) => item.question === props.index);
      if (i > -1) array[i] = item;
      else array.push(item);

      return array;
    };

    addVote({ question: props.index, answer: i, pollId: props.pollId });

    setAnswered(i);

    props.setUserAnswers(
      upsert(props.userAnswers, { answer: i, question: props.index })
    );
  };

  useEffect(() => {
    setAnswered(null);

    props.userAnswers.find((answer) => {
      if (answer.question === props.index) setAnswered(answer.answer);
      return answer;
    });
  }, [props.userAnswers, props.index]);

  return (
    <div className="questionWrapper">
      <div className="question">
        <div>{props.activeQuestion?.question}</div>
        <div className="questionNumber">{`${props.index + 1} / ${
          props.length
        }`}</div>
      </div>
      <div className="answersWrapper">
        {props.activeQuestion?.answers.map((ans, i) => (
          <Answer
            key={"ans" + props.index + "-" + i}
            answered={answered}
            answer={{ ans, i }}
            submitAnswer={(i) => submitAnswer(i)}
          />
        ))}
      </div>
    </div>
  );
}

export default Question;
