import React, { useState } from "react";

import "./Sidebar.scss";

import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";

import Button from "../Button/Button";

import { IconButton } from "@material-ui/core";

function Sidebar(props) {
  const [streaming, setStreaming] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [micStatus, setMicStatus] = useState(true);
  const [camStatus, setCamStatus] = useState(true);

  const askForPermission = () => {
    setWaiting(true);
    props.socketInstance.askForPermission();
    props.socketInstance.waitForAnswer = waitForAnswer;
  };

  const waitForAnswer = (canSpeak) => {
    if (canSpeak) {
      setWaiting(false);
      setStreaming(true);
    } else setWaiting(false);
  };

  const handleMyMic = () => {
    const { toggleAudioTrack } = props.socketInstance;
    toggleAudioTrack({ video: camStatus, audio: !micStatus });
    setMicStatus(!micStatus);
  };

  const handleMyCam = () => {
    const { toggleVideoTrack } = props.socketInstance;
    toggleVideoTrack({ video: !camStatus, audio: micStatus });
    setCamStatus(!camStatus);
  };

  return (
    <div className="sidebar">
      <div className="videoContainer">
        <div className="videoWrapper">
          <video className="video-presenter"></video>
        </div>
      </div>
      {streaming ? (
        <div className="sidebarAction">
          <div
            className={classnames("microphoneToggle", { active: micStatus })}
            onClick={handleMyMic}
          >
            {micStatus ? (
              <IconButton>
                <FontAwesomeIcon icon={faMicrophone} />
              </IconButton>
            ) : (
              <IconButton>
                <FontAwesomeIcon icon={faMicrophoneSlash} />
              </IconButton>
            )}
          </div>
          <div
            className={classnames("cameraToggle", { active: camStatus })}
            onClick={handleMyCam}
          >
            {camStatus ? (
              <IconButton>
                <FontAwesomeIcon icon={faVideo} />
              </IconButton>
            ) : (
              <IconButton>
                <FontAwesomeIcon icon={faVideoSlash} />
              </IconButton>
            )}
          </div>
        </div>
      ) : (
        <div className="askPermissionWrapper">
          <Button
            disabled={waiting}
            style={{ fontSize: ".85em" }}
            onClick={() => askForPermission()}
          >
            {waiting ? "Request send" : "Ask for permission to speak"}
          </Button>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
