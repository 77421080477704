import React, { useEffect, useState, useCallback } from "react";
import "./Admin.scss";

import Button from "../Button/Button";
import UIContainer from "./UIContainer";
import ActivePollWrapper from "./ActivePollWrapper";
import RegisterKeys from "./RegisterKeys/RegisterKeys";
import PollList from "./PollList/PollList";
import PollVotes from "./PollVotes/PollVotes";

import axios from "../../axios";

import { logoutUser } from "../../functions/actions/authActions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { createSocketConnectionInstance } from "../../functions/connection/connection";

function Admin(props) {
  const [socketInstance, setSocketInstance] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [votes, setVotes] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [origin, setOrigin] = useState(false);

  useEffect(() => {
    if (isActive || votes) startConnection(origin);
  }, [isActive, votes, origin]);

  const pollEnded = (data) => {
    setIsActive(null);
    setVotes(data);
  };

  const startConnection = (origin) => {
    let params = {
      startOrigin: origin,
      quality: 12,
      isAdmin: true,
      user: props.auth.user,
      pollID: isActive ? isActive._id : votes ? votes._id : null,
      pollEnded,
      showVotesEnded,
    };

    setSocketInstance(
      createSocketConnectionInstance({
        params,
      })
    );
  };

  const showVotesEnded = () => {
    setVotes(null);
    socketInstance?.destroyConnection();
  };

  const endPoll = () => {
    socketInstance?.socket.emit("poll-end", {
      user: props.auth.user,
      pollId: isActive._id,
    });
  };

  const endVotes = () => {
    socketInstance?.socket.emit("show-votes-end", {
      user: props.auth.user,
      pollId: votes._id,
    });
  };

  const addVote = (data) => {
    socketInstance?.socket.emit("vote", {
      ...data,
      userId: props.auth.user.id,
    });
  };

  const getActive = useCallback(async (origin) => {
    const req = await axios.get("/covid-poll-meeting/polls/get/active", {
      params: {
        _id: props.auth.user.id,
      },
    });

    if(origin) 
      setOrigin(true);

    if (req.data) {
      if (req.data.showVotes === true) setVotes(req.data);
      else setIsActive(req.data);
    }
  }, [props.auth.user.id]);

  useEffect(() => {
    getActive(false);
  }, [getActive]);

  return (
    <div className="ui-admin">
      <div className="up">
        <UIContainer classes="adminActionSidebar">
          <Button onClick={() => props.logoutUser(socketInstance?.socket)}>
            Log Out
          </Button>
        </UIContainer>
        <UIContainer>
          <RegisterKeys
            user={props.auth.user.id}
            isActive={isActive === null && votes === null ? false : true}
          />
        </UIContainer>
        <UIContainer>
          <PollList
            getActive={() => getActive(true)}
            isActive={isActive === null && votes === null ? false : true}
          />
        </UIContainer>
        <UIContainer>
          <PollVotes
            socket={socketInstance?.socket}
            isActive={isActive}
            questionIndex={questionIndex}
          />
        </UIContainer>
      </div>
      <div className="down">
        <ActivePollWrapper
          endPoll={endPoll}
          endVotes={endVotes}
          socketInstance={socketInstance}
          questionIndex={(i) => setQuestionIndex(i)}
          addVote={addVote}
          isActive={isActive}
          votes={votes}
          questionHandler={(index) => setQuestionIndex(index)}
        />
      </div>
    </div>
  );
}

Admin.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Admin));
