import React, { useState, useEffect } from "react";
import "./Poll.scss";

import Question from "./Question";
import QuestionNavigation from "./QuestionNavigation";

import axios from "../../axios";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

function Poll(props) {
  const [activePoll, setActivePoll] = useState({});
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);

  useEffect(() => {
    const getUserAnswers = async () => {
      const req = await axios.get("/covid-poll-meeting/polls/votes/user", {
        params: {
          _id: props.auth.user.id,
          pollId: activePoll._id,
        },
      });

      setUserAnswers(req.data);
    };

    if (props.auth.user.id && activePoll?._id) getUserAnswers();
  }, [props.auth.user.id, activePoll?._id]);

  useEffect(() => {
    if (props.questionIndex) props.questionIndex(activeQuestion);
  }, [activeQuestion, props]);

  useEffect(() => {
    setActivePoll(props.active);
    // if (props.active && activePoll?._id && props.socket)
      // props.socket?.emit("user-join-poll", { user: props.auth.user.id, pollID: activePoll?._id });
  }, [props.active]);

  return (
    <div className="activePoll">
      {activePoll ? (
        <div>
          <Question
            user={props.auth.user.id}
            socket={props.socket}
            length={activePoll?.questions?.length}
            index={activeQuestion}
            userAnswers={userAnswers}
            setUserAnswers={(val) => setUserAnswers(val)}
            activeQuestion={activePoll?.questions?.[activeQuestion]}
            pollId={activePoll?._id}
          />
          <QuestionNavigation
            faded={
              activeQuestion === 0
                ? "left"
                : activeQuestion === activePoll.questions.length - 1
                ? "right"
                : ""
            }
            leftButton={
              activeQuestion - 1 >= 0
                ? () => setActiveQuestion(activeQuestion - 1)
                : null
            }
            rightButton={
              activeQuestion + 1 < activePoll?.questions?.length
                ? () => setActiveQuestion(activeQuestion + 1)
                : null
            }
          />
        </div>
      ) : (
        <div className="noActivePoll">NO ACTIVE POLL</div>
      )}
    </div>
  );
}

Poll.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(Poll));
