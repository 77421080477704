import React, { useEffect, useState, useCallback } from "react";
import "./RegisterKeys.scss";

import Button from "../../Button/Button";

import classnames from 'classnames';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";

import axios from "../../../axios";

function RegisterKeys(props) {
  const [keys, setKeys] = useState([]);
  const [copied, setCopied] = useState("");

  const copyToClipboard = (key) => {
    setCopied("");
    navigator.clipboard.writeText("localhost:3000/register/" + key);
    setCopied(key);
  };

  const addKey = async () => {
    const req = await axios.post("/covid-poll-meeting/keys/add", {
      _id: props.user,
    });

    setKeys(req.data);
  };

  const deleteKey = async (keyId) => {
    const req = await axios.post("/covid-poll-meeting/keys/delete", {
      _id: props.user,
      keyId,
    });

    setKeys(req.data);
  };

  const getKeys = useCallback(async () => {
    const req = await axios.get("/covid-poll-meeting/keys/get", {
      params: {
        _id: props.user,
      },
    });

    setKeys(req.data);
  }, [props.user]);

  useEffect(() => {
    getKeys();
  }, [getKeys]);

  return (
    <div className="registerKeys">
      <div className="keyList">
        {keys.map((key, i) => (
          <div key={"key" + i} className={classnames("key", props.isActive ? "faded" : "")}>
            <div
              className="keyCodeWrapper"
              onClick={props.isActive ? null : () => copyToClipboard(key.key)}
            >
              <div className="keyCode" title="Copy to clipboard">
                {key.key}
              </div>
              <div title="Copy to clipboard">
                <FontAwesomeIcon icon={faCopy} />
                {copied === key.key ? (
                  <span className="copied">Copied!</span>
                ) : null}
              </div>
            </div>
            <div className="actionBar">
              <div className="deleteKey" onClick={props.isActive ? null : () => deleteKey(key._id)}>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  title={"Delete register key"}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="addKey">
        <Button
          disabled={props.isActive ? true : false}
          style={{ width: "100%" }}
          onClick={() => addKey()}
        >
          Add new key
        </Button>
      </div>
    </div>
  );
}

export default RegisterKeys;
