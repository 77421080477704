import React from "react";
import classnames from "classnames";

import Input from "../../Input/Input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

function Answer(props) {
  return (
    <div
      className={classnames(
        "single",
        props.editingAnswerActive ? "editing" : ""
      )}
    >
      <div className="questionNumber">{props.answerIndex + 1}</div>
      {props.editingAnswerActive ? (
        <div className="editAnswerText">Edit this answer</div>
      ) : null}
      <Input
        readOnly={props.editingAnswerActive ? false : true}
        type="text"
        value={props.editingAnswerActive ? props.editingAnswer.text : props.ans}
        onKeyDown={(e) => props.handleAnswerChange(e)}
        onChange={
          props.editingAnswerActive
            ? (e) =>
                props.setEditingAnswer({
                  ...props.editingAnswer,
                  text: e.target.value,
                })
            : null
        }
      />
      <div className="actionBoxAnswer">
        <div
          className="editAnswer"
          onClick={
            props.editingAnswerActive
              ? () => props.handleAnswerChange("change")
              : () =>
                  props.setEditingAnswer({
                    answer: { q: props.i, a: props.answerIndex },
                    text: props.ans,
                  })
          }
        >
          <FontAwesomeIcon icon={faEdit} />
        </div>
        <div
          className="deleteAnswer"
          onClick={() => props.deleteAnswer(props.i, props.answerIndex)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      </div>
    </div>
  );
}

export default Answer;
