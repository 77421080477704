import React from "react";
import './QuestionNavigation.scss';

import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import IconButton from "@material-ui/core/IconButton";

function QuestionNavigation(props) {
  return (
    <div className="questionNavigation">
      <div className={classnames("", { faded: props.faded === "left" })}>
        <div className="leftButton">
          <IconButton disabled={props.faded === "left"} onClick={() => props.leftButton?.()}>
            <FontAwesomeIcon
              icon={faChevronLeft}
            />
          </IconButton>
        </div>
        <div className="navText left">Previous question</div>
      </div>
      <div className={classnames("", { faded: props.faded === "right" })}>
        <div className="navText right">Next question</div>
        <div className="rightButton">
          <IconButton disabled={props.faded === "right"} onClick={() => props.rightButton?.()}>
            <FontAwesomeIcon
              icon={faChevronRight}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default QuestionNavigation;
