import React, { useState } from "react";

import UIContainer from "./UIContainer";
import AdminSidebar from "./AdminSidebar/AdminSidebar";
import Poll from "../Poll/Poll";
import Confirmation from "../Confirmation/Confirmation";
import Votes from '../Votes/Votes';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

import { IconButton } from "@material-ui/core";

function ActivePollWrapper(props) {
  const [confirmOff, setConfirmOff] = useState(false);

  return (
    <div className="ui-activePoll">
      <UIContainer classes="activePollWrapper">
        {props.isActive || props.votes ? (
          <div className="turnOffPoll">
            <Confirmation
              style={{
                color: "rgb(80, 80, 80)",
                position: "relative",
                marginRight: "5px",
              }}
              active={confirmOff}
              text="Are you sure?"
              classes="right"
              yesHandler={props.isActive ? () => props.endPoll() : () => props.endVotes()}
              noHandler={() => setConfirmOff(false)}
            />
            <IconButton
              title="End this poll"
              onClick={() => setConfirmOff(!confirmOff)}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </IconButton>
          </div>
        ) : null}
        {props.votes ? (
          <Votes poll={props.votes} classes="adminVotes" />
        ) : (
          <Poll
            active={props.isActive}
            addVote={props.addVote}
            questionIndex={props.questionIndex}
            socket={props.socketInstance?.socket}
          />
        )}
      </UIContainer>
      <UIContainer classes="adminPollSidebar">
        <AdminSidebar
          votes={props.votes}
          active={props.isActive}
          socketInstance={props.socketInstance}
        />
      </UIContainer>
    </div>
  );
}

export default ActivePollWrapper;
