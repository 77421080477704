import React from "react";
import './QuestionVotes.scss';

function QuestionVotes(props) {
  const getVotesCount = (array, answer) => {
    let count = array.filter((obj) => obj.answer === answer).length;

    return count ? ((100 * count) / array.length).toFixed(2).replace(/\.00$/, '') + " %" : "0 %";
  };

  return (
    <div className="questionVotesWrapper">
      <div className="questionText">
        <div className="question">{props.question.question}</div>
        <div className="questionNumber">{props.questionIndex + 1 + " / " + props.length}</div>
      </div>
      <div className="questionAnswers">
        {props.question.answers.map((answer, i) => (
          <div
            key={"answerVotes" + i}
            className="answer"
            style={{ height: 100 / props.question.answers.length + "%" }}
          >
            <div className="answerText">{answer}</div>{" "}
            <div className="votesPercentage">{getVotesCount(props.votes, i)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default QuestionVotes;
