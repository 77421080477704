import React, { useEffect, useState, useCallback } from "react";
import "./Main.scss";

import { logoutUser } from "../../functions/actions/authActions";

import axios from "../../axios";

import Poll from "../Poll/Poll";
import Button from "../Button/Button";
import Sidebar from "../Sidebar/Sidebar";
import Votes from "../Votes/Votes";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { createSocketConnectionInstance } from "../../functions/connection/connection";

function Main(props) {
  const [socketInstance, setSocketInstance] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [votes, setVotes] = useState(null);

  const startConnection = () => {
    let params = {
      quality: 12,
      user: props.auth.user,
      pollID: isActive ? isActive._id : votes ? votes._id : null,
      showVotesEnded,
      pollEnded,
    };

    setSocketInstance(
      createSocketConnectionInstance({
        params,
      })
    );
  };

  useEffect(() => {
    if(socketInstance?.socket)
      socketInstance.socket.on("poll-started", () => {
        getActive();
      })
  }, [socketInstance?.socket])

  const pollEnded = (data) => {
    setIsActive(null);
    setVotes(data);
  };

  useEffect(() => {
    socketInstance?.destroyConnection();
    startConnection();
  }, [isActive, votes]);

  const getActive = useCallback(async () => {
    const req = await axios.get("/covid-poll-meeting/polls/get/active", {
      params: {
        _id: props.auth.user.id,
      },
    });

    if (req.data) {
      if (req.data.showVotes === true) setVotes(req.data);
      else setIsActive(req.data);
    }
  }, [props.auth.user.id]);

  useEffect(() => {
    getActive();
  }, [getActive]);

  useEffect(() => {
    if (props.auth.isAdmin) props.history.push("/admin");
  }, [props]);

  const showVotesEnded = () => {
    setVotes(null);
    socketInstance?.destroyConnection();
  };

  return (
    <div className="main">
      <div className="userActions">
        <Button onClick={() => props.logoutUser(socketInstance?.socket)}>
          Log Out
        </Button>
      </div>
      <div className="mainPollWrapper">
        {votes ? (
          <div className="votesWrapper">
            <Votes poll={votes} classes="mainVotes" />
          </div>
        ) : (
          <Poll socket={socketInstance?.socket} active={isActive} />
        )}
        <Sidebar active={isActive} socketInstance={socketInstance} />
      </div>
    </div>
  );
}

Main.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Main));
