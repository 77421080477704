import React, { useEffect, useState } from "react";
import "./PollVotes.scss";

import axios from "../../../axios";

import QuestionVotes from "../../Votes/QuestionVotes";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

function PollVotes(props) {
  const [votes, setVotes] = useState([]);

  useEffect(() => {
    const getVotes = async () => {
      const req = await axios.get("/covid-poll-meeting/polls/votes/get", {
        params: {
          _id: props.auth.user.id,
          pollId: props.isActive._id,
        },
      });
      setVotes(req.data);
    };
    if (props.isActive?._id) getVotes();
  }, [props.auth.user.id, props.isActive?._id]);

  useEffect(() => {
    const voteHandler = (data) => {
      if (
        votes.find(
          (vote) => vote.question === data.question && vote.userId === data.userId
        )
      )
        setVotes(
          votes.map((vote) => {
            if (vote.question === data.question && vote.userId === data.userId)
              vote.answer = data.answer;

            return vote;
          })
        );
      else setVotes([...votes, data]);
    };

    if (props.socket) 
      props.socket.on("newVote", voteHandler);

    return () => props.socket ? props.socket.off("newVote") : null;
  }, [votes, props.socket]);

  return (
    <div className="pollVotesWrapper">
      {props.isActive ? (
        <QuestionVotes
          length={props.isActive?.questions?.length}
          questionIndex={props.questionIndex}
          question={props.isActive?.questions?.[props.questionIndex]}
          votes={votes.filter((vote) => vote.question === props.questionIndex)}
        />
      ) : <div className="noActivePoll">
        NO ACTIVE POLL
        </div>}
    </div>
  );
}

PollVotes.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(PollVotes));
