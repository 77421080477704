import React, { useEffect, useState } from "react";
import "./Register.scss";

import Input from "../Input/Input";
import Button from "../Button/Button";
import Error from "../Error/Error";

import { registerUser } from "../../functions/actions/authActions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../axios";

function Register(props) {
  const [isVerified, setIsVerified] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.auth.isAuthenticated) props.history.push("/");
  }, [props]);

  useEffect(() => {
    const verifyKey = async () => {
      const req = await axios.post("/covid-poll-meeting/keys/verify", {
        key: props.match.params.key,
      });
      console.log(req);
      if (!req.data.isValid) props.history.push("/login");
      else setIsVerified(true);
    };

    verifyKey();
  }, [props.match.params.key, props.history]);

  const registerUser = () => {
    setIsLoading(true);
    props.registerUser(props.history, {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      passwordRepeat,
    });
  };

  useEffect(() => {
    if (props.errors) {
      setIsLoading(false);
      setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <div className="registerContainer">
      {isVerified ? (
        <div className="registerWrapper">
          <div>
            <Input
              errors={errors.firstName}
              type="text"
              placeholder="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Error text={errors.firstName} />
          </div>
          <div>
            <Input
              errors={errors.lastName}
              type="text"
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <Error text={errors.lastName} />
          </div>
          <div>
            <Input
              errors={errors.email}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Error text={errors.email} />
          </div>
          <div>
            <Input
              errors={errors.phoneNumber}
              type="text"
              placeholder="Phone number"
              value={phoneNumber}
              maxLength={9}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <Error text={errors.phoneNumber} />
          </div>
          <div>
            <Input
              errors={errors.password}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Error text={errors.password} />
          </div>
          <div>
            <Input
              errors={errors.passwordRepeat}
              type="password"
              placeholder="Repeat password"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
            <Error text={errors.passwordRepeat} />
          </div>
          <div className="submitWrapper">
            <Button onClick={registerUser} loading={isLoading}>
              Sign-Up
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
