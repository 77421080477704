import React, { useEffect, useState } from "react";
import "./Login.scss";

import Input from "../Input/Input";
import Button from "../Button/Button";
import Error from "../Error/Error";

import { loginUser } from "../../functions/actions/authActions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.auth.isAdmin) props.history.push("/admin");
    else if (props.auth.isAuthenticated) props.history.push("/");
  }, [props]);

  useEffect(() => {
    if (props.errors) {
      setIsLoading(false);
      setErrors(props.errors);
    }
  }, [props.errors]);

  const loginUser = () => {
    setIsLoading(true);
    props.loginUser({ email, password });
  };

  return (
    <div className="loginContainer">
      <div className="loginWrapper">
        <div>
          <Input
            errors={errors.email}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if(e.keyCode === 13) {
                e.preventDefault();
                loginUser();
              }
            }}
          />
          <Error text={errors.email} />
        </div>
        <div>
          <Input
            errors={errors.password}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if(e.keyCode === 13) {
                e.preventDefault();
                loginUser();
              }
            }}
          />
          <Error text={errors.password} />
        </div>
        <div className="submitWrapper">
          <Button onClick={loginUser} loading={isLoading}>
            Sign-In
          </Button>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(withRouter(Login));
