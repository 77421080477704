import React, { useState, useEffect, useRef } from "react";
import "./Votes.scss";

import Chart from "chart.js";
import QuestionNavigation from "../Poll/QuestionNavigation";

import classnames from 'classnames';

import QuestionVotes from "./QuestionVotes";

function Votes(props) {
  const chartRef = useRef();
  const chart = useRef();

  const [chartData, setChartData] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [poll, setPoll] = useState({});
  const [questionIndex, setQuestionIndex] = useState(0);

  useEffect(() => {
    chart.current = new Chart(chartRef.current.getContext("2d"), {
      type: "pie",
    });
  }, [chartRef.current]);

  useEffect(() => {
    if (chartData) {
      chart.current.data.datasets = chartData.datasets;
      chart.current.data.labels = chartData.labels;
      chart.current.update();
    }
  }, [chartData]);

  useEffect(() => {
    if (poll.questions && answers) {
      let answerVotes = {};

      poll.questions[questionIndex].answers.forEach((el, i) => {
        answerVotes[i] = 0;
      });

      answers.forEach((el) => {
        if (answerVotes[el]) answerVotes[el]++;
        else answerVotes[el] = 1;
      });

      setChartData({
        labels: [...poll?.questions[questionIndex].answers],
        datasets: [
          {
            label: "# of Votes",
            data: [
              ...Object.keys(answerVotes).map((key) => {
                return answerVotes[key];
              }),
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [answers, poll]);

  useEffect(() => {
    setPoll(props.poll);
  }, [props.poll]);

  useEffect(() => {
    let filtered = poll?.answers?.filter(
      (ans) => ans.question === questionIndex
    );
    setAnswers(
      filtered?.map((ans) => {
        return ans.answer;
      })
    );
  }, [questionIndex, poll]);

  return (
    <div className={classnames("votes", props.classes)}>
      <div>
        {poll.questions ? (
          <div className="questionVotesContainer">
            <QuestionVotes
              length={poll?.questions?.length}
              questionIndex={questionIndex}
              question={poll?.questions?.[questionIndex]}
              votes={poll.answers.filter(
                (vote) => vote.question === questionIndex
              )}
            />
          </div>
        ) : null}
      </div>
      <div>
        <div className="chartContainer">
          <canvas ref={chartRef} id="votesChart"></canvas>
        </div>
        <div className="questionNavigationVotesWrapper">
          <QuestionNavigation
            faded={
              questionIndex === 0
                ? "left"
                : questionIndex === poll?.questions?.length - 1
                ? "right"
                : ""
            }
            leftButton={
              questionIndex - 1 >= 0
                ? () => setQuestionIndex(questionIndex - 1)
                : null
            }
            rightButton={
              questionIndex + 1 < poll?.questions?.length
                ? () => setQuestionIndex(questionIndex + 1)
                : null
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Votes;
