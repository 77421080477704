import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import PrivateRoute from "./functions/routes/PrivateRoute";
import AdminRoute from "./functions/routes/AdminRoute";

import AdminRouter from "./components/Admin/AdminRouter";
import Main from "./components/Main/Main";
import Login from "./components/Login/Login";
import Register from './components/Register/Register';

import setAuthToken from "./functions/utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { setCurrentUser, logoutUser } from "./functions/actions/authActions";

import store from "./functions/store/store";

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser);
    window.location.href = "/login";
  }
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/register/:key" component={Register} />
          <AdminRoute component={AdminRouter} exact path="/admin" />
          <AdminRoute component={AdminRouter} exact path="/admin/poll" />
          <AdminRoute component={AdminRouter} exact path="/admin/poll/:id" />
          <PrivateRoute component={Main} exact path="/" />
          <Redirect to="/" />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
