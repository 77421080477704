import React, { useState, useEffect } from "react";
import classnames from "classnames";

import Input from "../../Input/Input";
import Answer from "./Answer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

function Question(props) {
  const [questionText, setQuestionText] = useState("");

  useEffect(() => {
    if (questionText !== "") props.questionTextHandler(questionText, props.i);
  }, [questionText, props]);

  useEffect(() => {
    setQuestionText(props.question.question);
  }, [props.question.question]);

  return (
    <div className="questionWrapper">
      <div
        className={classnames(
          "question",
          props.activeQuestion === props.i ? "active" : ""
        )}
      >
        <div className="questionText">
          <Input
            readOnly={props.activeQuestion === props.i ? false : true}
            type="text"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
          />
        </div>
        <div className="showAnswers">
          <div>
            <FontAwesomeIcon
              onClick={
                props.activeQuestion === props.i
                  ? () => props.setActiveQuestion(null)
                  : () => props.setActiveQuestion(props.i)
              }
              title="Show question"
              icon={faQuestion}
            />
          </div>
          <div className="deleteQuestion">
            <FontAwesomeIcon title="Delete question" icon={faTrashAlt} onClick={
              props.activeQuestion === props.i
              ? () => props.deleteQuestion(props.i)
              : null
            } />
          </div>
        </div>
      </div>
      <div
        className={classnames(
          "answers",
          props.activeQuestion === props.i ? "active" : ""
        )}
      >
        {props.question.answers.map((ans, answerIndex) => (
          <Answer
            i={props.i}
            ans={ans}
            answerIndex={answerIndex}
            editingAnswer={props.editingAnswer}
            editingAnswerActive={
              props.editingAnswer.answer.q === props.i &&
              props.editingAnswer.answer.a === answerIndex
            }
            handleAnswerChange={props.handleAnswerChange}
            setEditingAnswer={props.setEditingAnswer}
            deleteAnswer={props.deleteAnswer}
          />
        ))}
        <div className="single addNew">
          <Input
            placeholder="Add new answer"
            type="text"
            value={props.newAnswer}
            onKeyDown={(e) => (e.keyCode === 13 ? () => props.addAnswer(props.i) : null)}
            onChange={(e) => props.setNewAnswer(e.target.value)}
          />
          <div className="actionBoxAnswer">
            <div className="addAnswer">
              <FontAwesomeIcon
                icon={faPlus}
                onClick={() => props.addAnswer(props.i)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Question;
