import React from "react";
import "./Input.scss";
import classnames from "classnames";

function Input(props) {
  return (
    <input
      readOnly={props.readOnly}
      onKeyDown={props.onKeyDown}
      spellCheck={false}
      placeholder={props.placeholder}
      type={props.type}
      name={props.name}
      id={props.id}
      maxLength={props.maxLength}
      onChange={props.onChange}
      value={props.value}
      className={classnames(
        "__styled",
        props.className,
        props.value ? "hasValue" : "",
        props.errors ? "__invalid" : ""
      )}
    />
  );
}

export default Input;
