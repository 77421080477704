import React from "react";
import {
  Switch,
} from "react-router-dom";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Admin from "./Admin";
import PollCreation from "./PollCreation/PollCreation";

import AdminRoute from "../../functions/routes/AdminRoute";

function AdminRouter(props) {
  return (
    <Switch>
      <AdminRoute component={PollCreation} exact path="/admin/poll" />
      <AdminRoute component={PollCreation} exact path="/admin/poll/:id" />
      <AdminRoute component={Admin} exact path="/admin" />
    </Switch>
  );
}

AdminRouter.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(AdminRouter));
