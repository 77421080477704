import React, { useState, useEffect, useCallback } from "react";
import "./AdminSidebar.scss";

import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";

import Button from "../../Button/Button";

import { IconButton } from "@material-ui/core";

function AdminSidebar(props) {
  const [micStatus, setMicStatus] = useState(true);
  const [camStatus, setCamStatus] = useState(true);
  const [permissionAsk, setPermissionAsk] = useState([]);
  // const [displayStream, setDisplayStream] = useState(false);

  const handlePermission = useCallback((user) => {
    setPermissionAsk([...permissionAsk, user]);
  }, [permissionAsk]);

  useEffect(() => {
    if (props.socketInstance)
      props.socketInstance.handlePermission = handlePermission;
  }, [props.socketInstance, handlePermission]);

  const grantPermission = (userData) => {
    props.socketInstance.socket.emit("grant-permission", userData)
    setPermissionAsk(permissionAsk.filter((user) => user.id !== userData.id))
  }

  const declinePermission = (userData) => {
    props.socketInstance.socket.emit("decline-ask-permission", userData)
    setPermissionAsk(permissionAsk.filter((user) => user.id !== userData.id))
  }

  const handleMyMic = () => {
    const { toggleAudioTrack } = props.socketInstance;
    toggleAudioTrack({ video: camStatus, audio: !micStatus });
    setMicStatus(!micStatus);
  };

  const handleMyCam = () => {
    // if (!displayStream) {
      const { toggleVideoTrack } = props.socketInstance;
      toggleVideoTrack({ video: !camStatus, audio: micStatus });
      setCamStatus(!camStatus);
    // }
  };

  return (
    <div className="adminSidebar">
      {props.active || props.votes ? (
        <div className="adminSidebar">
          <div className="videoContainer">
            <div className="videoWrapper">
              <video className="video-presenter"></video>
            </div>
          </div>
          <div className="sidebarAction">
            <div
              className={classnames("microphoneToggle", { active: micStatus })}
              onClick={handleMyMic}
            >
              {micStatus ? (
                <IconButton>
                  <FontAwesomeIcon icon={faMicrophone} />
                </IconButton>
              ) : (
                <IconButton>
                  <FontAwesomeIcon icon={faMicrophoneSlash} />
                </IconButton>
              )}
            </div>
            <div
              className={classnames("cameraToggle", { active: camStatus })}
              onClick={handleMyCam}
            >
              {camStatus ? (
                <IconButton>
                  <FontAwesomeIcon icon={faVideo} />
                </IconButton>
              ) : (
                <IconButton>
                  <FontAwesomeIcon icon={faVideoSlash} />
                </IconButton>
              )}
            </div>
          </div>
          <div className="permissionWrapper">
            {permissionAsk.map((user, i) => (
              <div className="permissionAsk" key={"userAsking" + i}>
                <div className="permissionText">{user.user.fullName} is asking for permission to speak</div>
                <div className="actionPermissionAsk">
                  <div>
                    <Button onClick={() => grantPermission(user)}>Accept</Button>
                  </div>
                  <div>
                    <Button onClick={() => declinePermission(user)}>Decline</Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="noActivePoll">NO ACTIVE POLL</div>
      )}
    </div>
  );
}

export default AdminSidebar;
