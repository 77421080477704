import React from "react";

import classnames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";

function Answer(props) {
  return (
    <div
      className={classnames("answer", {
        answered: props.answered === props.answer.i,
      })}
      onClick={() => props.submitAnswer(props.answer.i)}
    >
      <div>{props.answer.ans}</div>
      <div className="dot">
        <FontAwesomeIcon icon={faDotCircle} />
      </div>
    </div>
  );
}

export default Answer;
