import React, { useState, useEffect } from "react";
import "./PollCreation.scss";

import Input from "../../Input/Input";
import Button from "../../Button/Button";
import Question from "./Question";
import Error from "../../Error/Error";

import classnames from "classnames";

import axios from "../../../axios";

import { createPoll, editPoll } from "../../../functions/actions/pollActions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

function PollCreation(props) {
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [activeQuestion, setActiveQuestion] = useState(null);

  const [editingAnswer, setEditingAnswer] = useState({
    answer: { q: null, a: null },
    text: null,
  });

  const [newAnswer, setNewAnswer] = useState("");

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const getPoll = async () => {
      const req = await axios
        .get("/covid-poll-meeting/polls/get/single", {
          params: {
            _id: props.auth.user.id,
            pollId: props.match.params.id,
          },
        })
        .catch(() => props.history.push("/admin"));

      if (req) {
        setTitle(req.data.title);
        setQuestions(req.data.questions);
      }
    };

    if (props.match.params.id && props.auth.user.id) getPoll();
  }, [props.match.params.id, props.auth.user.id, props.history]);

  useEffect(() => {
    if (props.errors) setErrors(props.errors);
  }, [props.errors]);

  const createPoll = () => {
    props.createPoll(props.history, {
      _id: props.auth.user.id,
      title,
      questions,
    });
  };

  const editPoll = () => {
    props.editPoll(props.history, {
      _id: props.auth.user.id,
      pollId: props.match.params.id,
      title,
      questions,
    });
  };

  const addAnswer = (questionIndex) => {
    if (newAnswer !== "") {
      setQuestions(
        questions.map((q, qI) => {
          if(questionIndex === qI)
            q.answers = [...q.answers, newAnswer];
          return q;
        })
      );

      setNewAnswer("");
    }
  };

  const deleteAnswer = (questionIndex, answerIndex) => {
    setQuestions(
      questions.map((q, qI) => {
        q.answers = q.answers.filter((a, aI) => {
          return !(qI === questionIndex && aI === answerIndex);
        });
        return q;
      })
    );

    setEditingAnswer({
      answer: { q: null, a: null },
      text: null,
    });
  };

  const deleteQuestion = (index) => {
    setQuestions(
      questions.filter((q, i) => {
        return i !== index
      })
    )

    setActiveQuestion(null);
  }

  const handleAnswerChange = (e) => {
    if (e.keyCode === 13 || e === "change") {
      if(editingAnswer.text !== "") {
      setQuestions(
        questions.map((q, questionIndex) => {
          q.answers = q.answers.map((a, answerIndex) => {
            if (
              questionIndex === editingAnswer.answer.q &&
              answerIndex === editingAnswer.answer.a
            )
              a = editingAnswer.text;
            return a;
          });
          return q;
        })
      );
      } else {
        setQuestions(
          questions.map((q, questionIndex) => {
            q.answers = q.answers.filter((a, answerIndex) => {
              return !(
                questionIndex === editingAnswer.answer.q &&
                answerIndex === editingAnswer.answer.a
              )
            });
            return q;
          })
        );
      }

      setEditingAnswer({
        answer: { q: null, a: null },
        text: null,
      });
    }
  };

  const addQuestion = () => {
    if (newQuestion !== "") {
      setQuestions([
        ...questions,
        {
          question: newQuestion,
          answers: ["Yes", "No", "Undecided"],
        },
      ]);
      setNewQuestion("");
    }
  };

  const changeQuestionText = (text, index) => {
    setQuestions(
      questions.map((q, questionIndex) => {
        if (questionIndex === index) {
          q.question = text;
        }
        return q;
      })
    );
  };

  return (
    <div className="pollCreation">
      <div>
        <div className="titleWrapper">
          <Input
            errors={errors.title && title === ""}
            type="text"
            placeholder="Poll title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {errors.title && title === "" ? <Error text={errors.title} /> : null}
        </div>
        <div className="questionList">
          {questions.length > 0 ? (
            questions.map((question, i) => (
              <Question
                questionTextHandler={(text, index) => changeQuestionText(text, index)}
                question={question}
                i={i}
                activeQuestion={activeQuestion}
                newAnswer={newAnswer}
                editingAnswer={editingAnswer}
                setEditingAnswer={(val) => setEditingAnswer(val)}
                handleAnswerChange={(val) => handleAnswerChange(val)}
                setActiveQuestion={(val) => setActiveQuestion(val)}
                deleteAnswer={(qI, aI) => deleteAnswer(qI, aI)}
                addAnswer={(val) => addAnswer(val)}
                setNewAnswer={(val) => setNewAnswer(val)}
                deleteQuestion={(i) => deleteQuestion(i)}
              />
            ))
          ) : (
            <div style={{ textAlign: "center", fontSize: ".85em" }}>
              No questions added
            </div>
          )}
        </div>
        <div className="questionsWrapper">
          <Input
            errors={errors.questions && questions.length === 0}
            type="text"
            className="questionInput"
            placeholder="New question"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
          />
          <FontAwesomeIcon
            onClick={() => addQuestion()}
            title={newQuestion !== "" ? "Add question" : ""}
            icon={faPlusSquare}
            className={classnames(newQuestion !== "" ? "hasValue" : "")}
          />
        </div>
        {errors.questions && questions.length === 0 ? (
          <Error text={errors.questions} />
        ) : null}
        <div className="submitButton">
          <Button
            onClick={
              props.match.params.id ? () => editPoll() : () => createPoll()
            }
          >
            {props.match.params.id ? "Edit poll" : "Add new poll"}
          </Button>
        </div>
      </div>
    </div>
  );
}

PollCreation.propTypes = {
  createPoll: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { createPoll, editPoll })(
  withRouter(PollCreation)
);
